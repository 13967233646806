<template>
    <section id="Tickets">
        <BookSelector :books="managedBooks" :selectedName="profile.books[$route.params.book_id].name" />
        <h3 class="no-results" v-if="filteredBets.length === 0">No results available.</h3>
        <Bet v-for="bet in filteredBets" :key="bet[1].createdAt" :id="bet[0]" :bet="bet[1]" :wagers="wagers" :books="books" :ticketHolder="profiles[bet[1].user]" isTicket />
        <Filters v-if="Object.keys(profiles).length > 0" :filters="bettors" :selected="filter" @select="changeFilter" />
        <Options :options="options" :selected="2" hideShadow />
    </section>
</template>

<script>

    // Components.
    import Options from '@/components/Options.vue';
    import Bet from '@/components/Bet.vue';
    import BookSelector from '@/components/BookSelector.vue';
    import Filters from '@/components/Filters.vue';

    // Firebase.
    import { getBet, getWager } from '@/firebase/wagers.js';
    import { watchBook } from '@/firebase/books.js';
    import { getProfile } from '@/firebase/auth.js';

    // Misc.
    import { MANAGE_OPTIONS } from '@/constants.js';

    export default {
        name: 'Tickets',
        components: {
            Options,
            Bet,
            BookSelector,
            Filters,
        },
        data() {
            return {
                wagers: {},
                books: {},
                bets: {},
                profiles: {},
                filter: 'ALL',
            }
        },
        computed: {
            managedBooks() {
                return Object.entries(this.profile.books).filter(book => book[1].membership === 'bookie' || book[1].membership === 'admin');
            },
            options() {
                return MANAGE_OPTIONS.map(option => {
                    return { ...option, params: { book_id: this.$route.params.book_id } };
                });
            },
            filteredBets() {
                if (Object.keys(this.bets).length === 0) return [];
                return Object.entries(this.bets).filter(bet => {
                    if (this.filter === 'ALL') return true;
                    return this.filter === `${ this.profiles[bet[1].user].firstName.toUpperCase() } ${ this.profiles[bet[1].user].lastName.toUpperCase() }`;
                }).sort((a, b) => b[1].createdAt - a[1].createdAt);
            },
            bettors() {

                let bettors = ['ALL'];

                if (Object.keys(this.profiles).length === 0) return bettors;
                Object.values(this.profiles).map(profile => {
                    bettors = [...bettors, `${ profile.firstName.toUpperCase() } ${ profile.lastName.toUpperCase() }`];
                });

                return bettors;

            }
        },
        mounted() {
            this.$store.dispatch('setNavShadow', true);
        },
        created() {
            this.$store.dispatch('updateLoading', 1);
            watchBook(this.$route.params.book_id).on('value', this.listen);
        },
        watch: {
            $route(to, from) {
                this.$store.dispatch('updateLoading', 1);
                watchBook(from.params.book_id).off('value', this.listen);
                watchBook(to.params.book_id).on('value', this.listen);
            }
        },
        beforeDestroy() {
            watchBook(this.$route.params.book_id).off('value', this.listen);
        },
        methods: {
            listen(snapshot) {

                const val = snapshot.val();
                this.books[this.$route.params.book_id] = val;
                this.wagers = {};
                this.bets = {};

                if (val.wagers) {
                    for (let wager of Object.keys(val.wagers)) {

                        this.$store.dispatch('updateLoading', 1);

                        getWager(wager).then(snapshot => {
                            this.wagers = { ...this.wagers, [snapshot.key]: snapshot.val() };
                            this.$store.dispatch('updateLoading', -1);
                        });

                    }
                }

                if (val.bets) {
                    for (let bet of Object.keys(val.bets)) {

                        this.$store.dispatch('updateLoading', 1);

                        getBet(bet).then(snapshot => {

                            this.bets = { ...this.bets, [snapshot.key]: snapshot.val() };

                            getProfile(snapshot.val().user).then(snapshot => {
                                this.profiles = { ...this.profiles, [snapshot.key]: snapshot.val() };
                                this.$store.dispatch('updateLoading', -1);
                            });

                        });
                    }
                }

                this.$store.dispatch('updateLoading', -1);

            },
            changeFilter(filter) {
                this.filter = filter;
            },
        }
    }

</script>

<style lang="scss" scoped>

    @import '../styles/_variables.scss';

    #Tickets {
        padding: size(Large) + size(Medium) 0 50px + size(Medium) + size(Large);
    }

    .no-results {
        padding: 0 size(Medium);
        text-align: center;
    }

    .Bet:nth-of-type(2) {
        margin-top: 0;
    }

    .Filters {
        position: fixed !important;
        bottom: 50px;
        left: 0;
        z-index: 10;
    }

</style>
